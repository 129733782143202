(function (factory) {
    typeof define === 'function' && define.amd ? define('app', factory) :
    factory();
})((function () { 'use strict';

    // alert('High');
    document.addEventListener('DOMContentLoaded', () => {
      // Get the current page's path
      const currentPage = window.location.pathname.split("/").pop(); // Gets the current file name (e.g., "package.html")
      // Select all the menu links

      const menuLinks = document.querySelectorAll('.menu ul li a'); // Loop through each menu link and check if it matches the current page

      menuLinks.forEach(link => {
        const pageLink = link.getAttribute('href'); // Get the href value of the link
        // Remove the active class from all links initially

        link.classList.remove('active'); // Add the active class to the link that matches the current page

        if (pageLink === currentPage) {
          link.classList.add('active');
        }
      });
    }); // Select the element with class "menu"

    const menu = document.querySelector('.menu'); // Clone the selected element

    const mobileMenu = menu.cloneNode(true); // Change the class name of the cloned element

    mobileMenu.className = 'mobileMenu'; // Insert the cloned element after the original "menu"

    menu.after(mobileMenu);
    document.addEventListener("DOMContentLoaded", function () {
      var navIcon = document.getElementById('nav-icon2');
      var mobileMenu = document.querySelector('.mobileMenu');
      var body = document.body;
      navIcon.addEventListener('click', function () {
        // Toggle the classes on the navIcon and mobileMenu
        navIcon.classList.toggle('open');
        mobileMenu.classList.toggle('active'); // Disable or enable scrolling on the body

        if (mobileMenu.classList.contains('active')) {
          body.style.overflow = 'hidden'; // Disable scroll
        } else {
          body.style.overflow = ''; // Enable scroll
        }
      });
    }); // Wait for the DOM to be fully loaded
    // document.addEventListener('DOMContentLoaded', function() {
    //     // Get the current page URL
    //     const currentPage = window.location.pathname.split("/").pop();
    //     console.log('currentPage', currentPage);
    //     // Select all menu links
    //     const menuLinks = document.querySelectorAll('.menu ul li a');
    //     // Loop through each menu link
    //     menuLinks.forEach(link => {
    //         // If the link href matches the current page URL, add 'active' class
    //         if (link.getAttribute('href') === currentPage) {
    //             // Remove 'active' from any previously set items
    //             document.querySelector('.menu ul li a.active')?.classList.remove('active');
    //             // Add 'active' to the current link
    //             link.classList.add('active');
    //         }
    //     });
    // });

}));
